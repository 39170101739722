/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  MenuCourse,
  MenuGroup,
} from '../../../../../frontend-api/src/interfaces/shop-setting';
import { Course } from '../../../@interfaces/course';
import { Shop } from '../../../@interfaces/shop';
import { MenuItem, MenuSetting } from '../../../@interfaces/shop-setting';
import { Workspace } from '../../../@interfaces/workspace';
import { LOGO_BASE_URL } from '../../../models/theme';
import { margeParams } from '../../../utils/query-storage';
import Description from './Description';

type Props = {
  workspace: Workspace;
  primaryColor: string;
  shop: Shop;
  courses: Course[];
  menuSetting: MenuSetting | null;
};

const styles = {
  container: css({
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '10px',
    margin: '10px auto',
    boxShadow: '0 3px 6px rgba(44,40,40,0.11)',
  }),
  courseName: css({
    fontWeight: 'bold',
    fontSize: '18px',
  }),
  button: css({
    display: 'block',
    background: '#172B4D',
    color: '#FFFFFF',
    borderRadius: '56px',
    padding: '9px 22px',
    marginLeft: '8px',
    border: 'none',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    '@media (max-width:320px)': {
      padding: '9px 8px',
      fontSize: '11px',
    },
  }),
  description: css({
    '& p': {
      marginTop: '16px',
      fontSize: '14px',
    },
  }),
};

export default function Courses(props: Props) {
  const { workspace, primaryColor, shop, courses, menuSetting } = props;

  return (
    <div style={{ padding: '0 20px' }}>
      {menuSetting ? (
        <div>
          <CustomCourseList
            workspaceUid={workspace.uid}
            primaryColor={primaryColor}
            shop={shop}
            courses={courses}
            menuSetting={menuSetting}
          />
        </div>
      ) : (
        <div>
          <StandardCourseList
            workspaceUid={workspace.uid}
            primaryColor={primaryColor}
            shop={shop}
            courses={courses}
          />
        </div>
      )}
    </div>
  );
}

const StandardCourseList = ({
  workspaceUid,
  primaryColor,
  shop,
  courses,
}: {
  workspaceUid: string;
  primaryColor: string;
  shop: Shop | undefined;
  courses: Course[];
}) => {
  return (
    <div>
      {courses.map((course) => {
        const fullUrl = new URL(
          margeParams(
            `/a/${workspaceUid}/shops/${shop?.uid}/courses/${course.uid}`
          )
        );
        const url = `${fullUrl.pathname}${fullUrl.search}`;
        return (
          <div key={course.id} css={styles.container}>
            {course.imagePath == null ? (
              <div>
                <h3 css={styles.courseName} style={{ color: primaryColor }}>
                  {course.name}
                </h3>
                <div
                  style={{
                    width: '100%',
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Link
                    to={url}
                    css={styles.button}
                    style={{ background: primaryColor }}
                  >
                    {getButtonLabelByCourse(course)}
                  </Link>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '100px',
                  }}
                >
                  <img
                    src={`${LOGO_BASE_URL}/${course.imagePath}`}
                    style={{
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{ paddingLeft: '10px', width: 'calc(100% - 100px)' }}
                >
                  <h3 css={styles.courseName} style={{ color: primaryColor }}>
                    {course.name}
                  </h3>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Link
                      to={url}
                      css={styles.button}
                      style={{ background: primaryColor }}
                    >
                      {getButtonLabelByCourse(course)}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <Description
              description={course.description}
              enableShowButton={true}
              primaryColor={primaryColor}
            />
          </div>
        );
      })}
    </div>
  );
};

const getButtonLabelByCourse = (course: Course) => {
  return course.buttonLabel || '空席確認・予約する';
};

const getButtonLabelByGroup = (item: MenuGroup) => {
  return item.buttonLabel || '空席確認・予約する';
};

const getAllGroups = (items: MenuItem[]): MenuGroup[] => {
  return items.flatMap((item) =>
    item.type === 'group' ? [item, ...getAllGroups(item.items)] : []
  );
};

const isMenuGroups = (
  items: (MenuItem | undefined)[]
): items is MenuGroup[] => {
  return (
    Array.isArray(items) &&
    items.every((item) => item !== undefined && item.type === 'group')
  );
};

const CustomCourseList = ({
  workspaceUid,
  primaryColor,
  shop,
  courses,
  menuSetting,
}: {
  workspaceUid: string;
  primaryColor: string;
  shop: Shop | undefined;
  courses: Course[];
  menuSetting: MenuSetting;
}) => {
  const history = useHistory();
  const [selectedGroups, setSelectedGroups] = useState<MenuGroup[]>([]);

  useEffect(() => {
    const handleBrowserBack = () => {
      history.go(0);
    };
    window.addEventListener('popstate', handleBrowserBack);
    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [history]);

  useEffect(() => {
    const allGroups = getAllGroups(menuSetting.items);
    const params = new URLSearchParams(window.location.search);
    const groupIds = params.get('group')?.split('/') || [];
    const newSelectedGroups = groupIds.map((groupId) =>
      allGroups.find((item) => item.uid === groupId)
    );
    if (!isMenuGroups(newSelectedGroups)) {
      return;
    }
    setSelectedGroups(newSelectedGroups);
  }, [menuSetting.items]);

  const moveTo = (menuGroups: MenuGroup[]) => {
    setSelectedGroups(menuGroups);
    window.scroll({ top: 0 });

    // groupパラメータに現在表示中のグループリストを保存
    const url = new URL(window.location.href);
    const groupPaths = menuGroups.map((group) => group.uid).join('/');
    if (groupPaths) {
      url.searchParams.set('group', groupPaths);
    } else {
      url.searchParams.delete('group');
    }
    history.push(`${url.pathname}${url.search}`);
  };

  const buildMenuGroup = (item: MenuGroup) => {
    const handleClickGroup = () => {
      moveTo([...selectedGroups, item]);
    };
    return (
      <div key={item.uid} css={styles.container}>
        {item.imagePath ? (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '100px',
              }}
            >
              <img
                src={`${LOGO_BASE_URL}/${item.imagePath}`}
                style={{
                  width: '100%',
                }}
                alt=""
              />
            </div>
            <div style={{ marginLeft: '10px', width: 'calc(100% - 100px)' }}>
              <h3 css={styles.courseName} style={{ color: primaryColor }}>
                {item.title}
              </h3>
              <div
                style={{
                  width: '100%',
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <button
                  onClick={handleClickGroup}
                  css={styles.button}
                  style={{ background: primaryColor }}
                >
                  {getButtonLabelByGroup(item)}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h3 css={styles.courseName} style={{ color: primaryColor }}>
              {item.title}
            </h3>
            <div
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                onClick={handleClickGroup}
                css={styles.button}
                style={{ background: primaryColor }}
              >
                {getButtonLabelByGroup(item)}
              </button>
            </div>
          </div>
        )}
        <Description
          description={item.description}
          enableShowButton={true}
          primaryColor={primaryColor}
        />
      </div>
    );
  };

  const buildMenuCourse = (item: MenuCourse) => {
    const course = courses?.find((c) => c.id === item.courseId);
    if (!course) {
      return null;
    }
    const buildCourseUrl = () => {
      const group = selectedGroups.map((group) => group.uid).join('/');
      const url = new URL(
        margeParams(
          `/a/${workspaceUid}/shops/${shop?.uid}/courses/${course.uid}`
        )
      );
      const params = new URLSearchParams(window.location.search);
      if (group) {
        url.searchParams.set('group', group);
      }
      return url.toString();
    };

    return (
      <div key={course.id} css={styles.container}>
        {course.imagePath == null ? (
          <div>
            <h3 css={styles.courseName} style={{ color: primaryColor }}>
              {course.name}
            </h3>
            <div
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <a
                href={buildCourseUrl()}
                css={styles.button}
                style={{ background: primaryColor }}
              >
                {getButtonLabelByCourse(course)}
              </a>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <div>
              <div
                style={{
                  width: '100px',
                }}
              >
                <img
                  src={`${LOGO_BASE_URL}/${course.imagePath}`}
                  style={{
                    width: '100%',
                  }}
                  alt=""
                />
              </div>
            </div>
            <div style={{ paddingLeft: '10px', width: '100%' }}>
              <h3 css={styles.courseName} style={{ color: primaryColor }}>
                {course.name}
              </h3>
              <div
                style={{
                  width: '100%',
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <a
                  href={buildCourseUrl()}
                  css={styles.button}
                  style={{ background: primaryColor }}
                >
                  {getButtonLabelByCourse(course)}
                </a>
              </div>
            </div>
          </div>
        )}
        <Description
          description={course.description}
          enableShowButton={true}
          primaryColor={primaryColor}
        />
      </div>
    );
  };

  const buildItem = (item: MenuItem) => {
    if (item.type === 'group') {
      return buildMenuGroup(item);
    } else if (item.type === 'course') {
      return buildMenuCourse(item);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = item;
    }
  };

  const buildItems = (items: MenuItem[]) => {
    return items.map((item) => buildItem(item));
  };

  const items = useMemo((): MenuItem[] => {
    return (
      selectedGroups[selectedGroups.length - 1]?.items || menuSetting.items
    );
  }, [selectedGroups, menuSetting.items]);

  return (
    <div>
      <ul className="bread">
        <li>
          <button
            style={{ color: primaryColor, border: 'none', cursor: 'pointer' }}
            onClick={() => {
              moveTo([]);
            }}
          >
            トップ
          </button>
        </li>
        {selectedGroups.map((group, index) => (
          <li key={`pan${index}`}>
            <button
              style={{ color: primaryColor, border: 'none', cursor: 'pointer' }}
              onClick={() => {
                moveTo([...selectedGroups.splice(0, index + 1)]);
              }}
            >
              {group.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="course-list">{courses ? buildItems(items) : null}</div>
    </div>
  );
};

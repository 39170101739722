/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import { useHistory, useParams } from 'react-router-dom';
import { CustomerLastInputOverview } from '../../../../@interfaces/customer-last-input';
import { PublicWorkspaceSetting } from '../../../../@interfaces/workspace-setting';
import { styles as commonStyles } from '../../../../components/styles';
import {
  AnyField,
  Field,
  FieldResponseValue,
  FormResponse,
} from '../../../../core/types/reservation-form-types';
import LeftArrow from '../../../../image/left_arrow.svg';
import { lineBreakToParagraphAndHtml } from '../../../../utils/browsers';
import { buildBackUrl } from './form-utils';
import { FormFields } from './FormFields';
import { LineLoginAutoFillNotice } from './LineLoginAutoFillNotice';
import { PrivacyPolicy } from './PrivacyPolicy';

const styles = {
  reserveButton: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    color: '#fff',
    width: '70%',
    maxWidth: '350px',
    padding: '16px 0',
    margin: '40px auto 0',
    background: '#172B4D',
    borderRadius: '56px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: '.8',
    },
    '&:disabled': {
      cursor: 'auto',
      background: '#777 !important',
    },
    '@media (max-width:320px)': {
      fontSize: '14px',
    },
  }),
  reserveButtonWithPrivacy: css({
    marginTop: '24px',
  }),
  dateChangeButton: css({
    display: 'block',
    margin: '32px auto',
    fontSize: '14px',
    borderRadius: '32px',
    color: '#172B4D',
    backgroundColor: '#FFFFFF',
    border: 'none',
    padding: '4px 16px',
    cursor: 'pointer',
    img: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '6px',
    },
    span: {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingTop: '2px',
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#172B4D',
    },
  }),
};

type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
};

type FormProps = {
  workspaceSetting: PublicWorkspaceSetting | undefined;
  fields: AnyField[];
  formFooter: string | undefined;
  formResponse: FormResponse;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
  onChangeValues?: (
    fields: { field: Field; newValues: FieldResponseValue[] }[]
  ) => void;
  onSubmitForm?: (e: React.FormEvent) => void;
  onSubmitFormChangeReservation?: (e: React.FormEvent) => void;
  submitting: boolean;
  primaryColor: string;
  reservationKey: string | null;
  customerLastInput?: CustomerLastInputOverview;
};
export function Form(props: FormProps) {
  const history = useHistory();
  const { workspaceUid, shopUid, courseUid } = useParams<PageParams>();
  const {
    workspaceSetting,
    fields,
    formFooter,
    formResponse,
    onChangeValue,
    onChangeValues,
    onSubmitForm,
    onSubmitFormChangeReservation,
    submitting,
    primaryColor,
    reservationKey,
    customerLastInput,
  } = props;
  const validationContext = useForm({ reValidateMode: 'onBlur' });
  const { handleSubmit } = validationContext;
  const disabledSubmit = submitting;

  const handleChangeDate = () => {
    const url = buildBackUrl(workspaceUid, shopUid, courseUid);
    history.push(url);
  };

  const buildLoading = () => {
    return (
      <div>
        {submitting ? (
          <ReactLoading
            className="loading"
            type="spokes"
            color={'#fff'}
            height={'20px'}
            width={'20px'}
          />
        ) : null}
      </div>
    );
  };

  const buildNewReservationForm = () => {
    return (
      <div>
        <form onSubmit={onSubmitForm ? handleSubmit(onSubmitForm) : undefined}>
          <FormFields
            fields={fields}
            formResponse={formResponse}
            primaryColor={primaryColor}
            validationContext={validationContext}
            onChangeValue={onChangeValue}
            onChangeValues={onChangeValues}
            customerLastInput={customerLastInput}
          />
          {formFooter && (
            <div
              className="form-message"
              css={css`
                margin-top: 16px;
              `}
            >
              {lineBreakToParagraphAndHtml(formFooter)}
            </div>
          )}
          <LineLoginAutoFillNotice />
          <PrivacyPolicy
            privacyPolicy={workspaceSetting?.privacyPolicy || ''}
            primaryColor={primaryColor}
          />
          <div>
            <button
              type="submit"
              disabled={disabledSubmit}
              css={[
                styles.reserveButton,
                workspaceSetting?.privacyPolicy
                  ? styles.reserveButtonWithPrivacy
                  : null,
              ]}
              style={{ background: primaryColor }}
            >
              <span style={{ marginRight: '8px' }}>{buildLoading()}</span>
              {workspaceSetting?.privacyPolicy
                ? '上記に同意して予約する'
                : '予約する'}
            </button>
            <button css={styles.dateChangeButton} onClick={handleChangeDate}>
              <img src={LeftArrow} alt="" />
              <span>日程選択に戻る</span>
            </button>
          </div>
        </form>
      </div>
    );
  };

  const buildChangeReservationForm = () => {
    return (
      <div>
        <div css={commonStyles.info} style={{ marginLeft: '0' }}>
          上記の日時に予約を変更します。
        </div>
        <form
          onSubmit={
            onSubmitFormChangeReservation
              ? handleSubmit(onSubmitFormChangeReservation)
              : undefined
          }
        >
          {formFooter && (
            <div className="form-message">
              {lineBreakToParagraphAndHtml(formFooter)}
            </div>
          )}
          <div>
            <button
              type="submit"
              disabled={disabledSubmit}
              css={styles.reserveButton}
              style={{ background: primaryColor }}
            >
              <span style={{ marginRight: '8px' }}>{buildLoading()}</span>
              予約を変更する
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div>
      {reservationKey
        ? buildChangeReservationForm()
        : buildNewReservationForm()}
    </div>
  );
}
